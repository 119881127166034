<template>
  <v-container>
  <div class="d-flex flex-row flex-wrap justify-start">
    <v-card v-for="item in items" :key="item.id" width="175" max-height="150" hover @click="view(item)" class="mx-2 my-2">
      <v-img
        v-if="item.keyImageMediaItemUrl"
        class="white--text align-end"
        height="150px"
        width="175px"
        :src="item.keyImageMediaItemUrl"
        gradient="to top, rgba(0, 0, 0, 0.4) 0%, transparent 72px">
        <v-card-subtitle class="no-wrap text-no-wrap px-1 py-0 mx-auto text-left">{{ item.shortId | truncate(24) }}</v-card-subtitle>
        <v-card-title class="no-wrap text-no-wrap px-1 py-0 mx-auto text-left">{{ item.name }}</v-card-title>
      </v-img>

      <v-img
        v-else
        src="@/assets/avatar.png"
        height="150px"
        width="175px"
        class="white--text align-end"
        gradient2="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        gradient="to top, rgba(0, 0, 0, 0.4) 10%, transparent 90px"
        >
        <v-card-subtitle class="no-wrap text-no-wrap px-1 py-0 mx-auto text-left">{{ item.shortId | truncate(24) }}</v-card-subtitle>
        <v-card-title class="no-wrap text-no-wrap px-1 py-0 mx-auto text-left">{{ item.name | truncate(24) }}</v-card-title>
      </v-img>
    </v-card>
  </div>
    <v-pagination
      v-if="paging.totalPages > 1"
      v-model="paging.currentPage"
      :length="paging.totalPages"
      :total-visible="5"
    ></v-pagination>
  </v-container>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true
      },
      meta: {
        type: Object,
        required: false
      }
    },
    computed:{
      paging() {
        if (this.meta) {
          return this.meta.paging
        }
        return {}
      }
    },
    methods: {
      view(item) {
        this.$emit('view', item)
      }
    }
  }
  </script>
